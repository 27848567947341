import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  PatrickServiceClient,
  ValidateUserRequest,
  ValidateUserResponse,
} from '@policyfly/protobuf'

import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface UserApiEndpoints {
  /**
   * Registers a 2FA device that has already been provisioned from one of the following endpoints:
   * - {@link api.users.provisionSMS provisionSMS}
   * - {@link api.users.provisionQR provisionQR}
   * - {@link api.users.emailChallenge emailChallenge}
   *
   * Generally this is only used during onboarding to validate the first 2FA device for a user.
   * However it can also be used to update a QR (aka TOTP) device.
   */
  register2FA: (params: { email: string, token: string, mode: 'sms' | 'totp' | undefined }) => Promise<void>
  /**
   * Validates that a user has an active account.
   *
   * @returns Information about available 2FA devices for this account.
   */
  validateUser: (params: ValidateUserRequest) => Promise<ValidateUserResponse>
  /**
   * Authenticates a user with the provided login information.
   * Will update the stored API tokens for for any future requests.
   */
  login: (params: LoginRequest) => Promise<void>
  /**
   * Sets up the user account for the first time and logs them in.
   * Will update the stored API tokens for for any future requests.
   */
  setupAccount: (params: { email: string, password: string, token: string }) => Promise<{ id: number }>
  /**
   * Tries to log the user out of the application.
   * Errors are simply ignored and not thrown.
   *
   * Tokens will be cleared from storage regardless of the result.
   */
  logout: () => Promise<void>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createUserEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<UserApiEndpoints> => {
  const patrickServiceClientAuth = new PatrickServiceClient(params.transport)
  const patrickServiceClientAnon = new PatrickServiceClient(params.transportAnonymous)

  const register2FA: UserApiEndpoints['register2FA'] = async ({ email, token, mode }) => {
    await api.users.auth({ body: { email, otp_token: token, device_mode: mode } })
  }

  const validateUser: UserApiEndpoints['validateUser'] = async ({ email, password }) => {
    const request = ValidateUserRequest.create({ email, password })
    const { response } = await patrickServiceClientAnon.validateUser(request)

    devtools.logGrpc({
      description: 'Validate User',
      messages: [
        { type: ValidateUserRequest, key: 'request', message: request },
        { type: ValidateUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  function loadTokens (response: LoginResponse): void {
    params.refreshToken.value = response.jwt?.refresh ?? ''
    params.accessToken.value = response.jwt?.access ?? ''

    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + response.sessionLength! / 60)
    params.spaToken.value = response.spaToken
    params.spaTokenExpiration.value = expirationDate.toISOString()
  }

  const login: UserApiEndpoints['login'] = async ({ email, password, otpToken, rememberMe }) => {
    const request = LoginRequest.create({ email, password, otpToken, rememberMe })
    const { response } = await patrickServiceClientAnon.login(request)

    devtools.logGrpc({
      description: 'Login',
      messages: [
        { type: LoginRequest, key: 'request', message: request },
        { type: LoginResponse, key: 'response', message: response },
      ],
    })

    if (!response.tokenValidated) throw new Error('Invalid token.')

    loadTokens(response)
  }

  const setupAccount: UserApiEndpoints['setupAccount'] = async ({ email, password, token }) => {
    const res = await api.users.setupAccount({ body: { email, password, otp_token: token } })

    loadTokens(LoginResponse.create({
      // @ts-expect-error: Swagger definitions do not exist on the api endpoints
      jwt: res.data.jwt,
      sessionLength: res.data.session_length,
      spaToken: res.data.spa_token,
      tokenValidated: true,
    }))

    return {
      id: res.data.user.id,
    }
  }

  const logout: UserApiEndpoints['logout'] = async () => {
    try {
      await patrickServiceClientAuth.logout(LogoutRequest.create())
    } catch {
      // ignore errors
    } finally {
      params.logout()
    }
  }

  const endpoints: UserApiEndpoints = {
    register2FA,
    validateUser,
    login,
    setupAccount,
    logout,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
