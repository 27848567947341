import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useLocalStorageStore } from '@/stores/localStorage'
import { useSettingsStore } from '@/stores/settings'

import { createApplicationEndpoints } from './endpoints/application'
import { createAttachmentEndpoints } from './endpoints/attachment'
import { createCommentEndpoints } from './endpoints/comment'
import { createEventEndpoints } from './endpoints/event'
import { createPolicyEndpoints } from './endpoints/policy'
import { createSchemaEndpoints } from './endpoints/schema'
import { createTaskEndpoints } from './endpoints/task'
import { createUserEndpoints } from './endpoints/user'
import { createVersionEndpoints } from './endpoints/version'
import { createAuthRpcInterceptor, createSlugRpcInterceptor } from './interceptors'
import { hotReloadStore } from '@/utils/build'

import type { ApplicationApiEndpoints } from './endpoints/application'
import type { AttachmentApiEndpoints } from './endpoints/attachment'
import type { CommentApiEndpoints } from './endpoints/comment'
import type { EventApiEndpoints } from './endpoints/event'
import type { PolicyApiEndpoints } from './endpoints/policy'
import type { SchemaApiEndpoints } from './endpoints/schema'
import type { TaskApiEndpoints } from './endpoints/task'
import type { UserApiEndpoints } from './endpoints/user'
import type { VersionApiEndpoints } from './endpoints/version'
import type { Ref, ComputedRef, WritableComputedRef, DeepReadonly } from 'vue'

export interface ApiStore {
  /**
   * Long-lived token used to retrieve short-lived access tokens.
   * These access tokens are then used to authenticate with GRPC services.
   * Currently this is only updated when the user logs in.
   *
   * If the refresh token is marked as expired (either by JWT expiration or by the refresh endpoint)
   * then the user is logged out and all tokens are invalidated.
   *
   * This is a read-only value, to change this use `localStorageStore.setItem('refreshToken', value)`.
   */
  refreshToken: ComputedRef<string | null>
  /**
   * Long-lived token used to authenticate against Django endpoints.
   * Currently this is only updated when the user logs in.
   *
   * _Note this is not actually a JWT and instead some base64 encoded JSON._
   *
   * If this token is marked as expired (either by {@link spaTokenExpiration} or by a Django 401 response)
   * then the user is logged out and all tokens are invalidated.
   *
   * This is a read-only value, to change this use `localStorageStore.setItem('spaToken', value)`.
   */
  spaToken: ComputedRef<string | null>
  /**
   * A hard-coded expiration date for the current {@link spaToken}.
   *
   * If this date is in the past when a Django endpoint is hit
   * then the user is logged out and all tokens are invalidated.
   *
   * This is a read-only value, to change this use `localStorageStore.setItem('spaTokenExpiration', value)`.
   */
  spaTokenExpiration: ComputedRef<string | null>
  transport: GrpcWebFetchTransport | null
  /**
   * Removes all stored authentication tokens used to access the API.
   *
   * **NOTE:** This does not call any logout endpoints on the server.
   * Use the `user.logout` endpoint to do so.
   */
  logout: () => void
  /**
   * Endpoints
   */
  application: ComputedRef<ApplicationApiEndpoints>
  attachment: ComputedRef<AttachmentApiEndpoints>
  comment: ComputedRef<CommentApiEndpoints>
  event: ComputedRef<EventApiEndpoints>
  policy: ComputedRef<PolicyApiEndpoints>
  schema: ComputedRef<SchemaApiEndpoints>
  task: ComputedRef<TaskApiEndpoints>
  user: ComputedRef<UserApiEndpoints>
  version: ComputedRef<VersionApiEndpoints>
}

export interface CreateInterceptorParams {
  /**
   * See {@link ApiStore.refreshToken store refreshToken}.
   *
   * This is a writable version of that token.
   */
  refreshToken: WritableComputedRef<string | null>
  /**
   * A short-lived token used to authenticate against GRPC services.
   *
   * If this is marked as expired (either by JWT expiration or by a GRPC service error)
   * then the {@link ApiStore.refreshToken refreshToken} is used to retrieve a new token.
   */
  accessToken: Ref<string | null>
  /**
   * See {@link ApiStore.spaToken store spaToken}.
   *
   * This is a writable version of that token.
   */
  spaToken: WritableComputedRef<string | null>
  /**
   * See {@link ApiStore.spaTokenExpiration store spaTokenExpiration}.
   *
   * This is a writable version of that value.
   */
  spaTokenExpiration: WritableComputedRef<string | null>
  /**
   * An anonymous transport used to make GRPC requests.
   * This does not include or try to fetch any authentication tokens.
   */
  transportAnonymous: DeepReadonly<GrpcWebFetchTransport>
}

export interface CreateEndpointParams extends CreateInterceptorParams {
  /**
   * The authenticated transport used to make GRPC requests.
   */
  transport: DeepReadonly<GrpcWebFetchTransport>
  /**
   * @see {@link ApiStore.logout logout}.
   */
  logout: ApiStore['logout']
}

export type ApiVariations = 'grpc' | 'django'
export type ApiVariableEndpoints<T extends object> = Record<ApiVariations, T>

/**
 * Returns the centralized api store.
 * This branches to either GRPC services or the legacy Django endpoints depending on program configuration.
 */
export const useApiStore = defineStore('api', (): ApiStore => {
  const localStorageStore = useLocalStorageStore()

  const refreshTokenWritable: CreateEndpointParams['refreshToken'] = localStorageStore.createItemRef('refreshToken')
  const accessTokenWritable: CreateEndpointParams['accessToken'] = ref(null)
  const spaTokenWritable: CreateEndpointParams['spaToken'] = localStorageStore.createItemRef('spaToken')
  const spaTokenExpirationWritable: CreateEndpointParams['spaTokenExpiration'] = localStorageStore.createItemRef('spaTokenExpiration')

  const logout: ApiStore['logout'] = () => {
    refreshTokenWritable.value = null
    accessTokenWritable.value = null
    spaTokenWritable.value = null
    spaTokenExpirationWritable.value = null
  }

  const transportAnonymous = new GrpcWebFetchTransport({
    baseUrl: window.location.origin,
    format: 'binary',
    interceptors: [
      createSlugRpcInterceptor(),
    ],
  })
  const interceptorParams: CreateInterceptorParams = {
    refreshToken: refreshTokenWritable,
    accessToken: accessTokenWritable,
    spaToken: spaTokenWritable,
    spaTokenExpiration: spaTokenExpirationWritable,
    transportAnonymous,
  }

  const transport = new GrpcWebFetchTransport({
    baseUrl: window.location.origin,
    format: 'binary',
    interceptors: [
      createAuthRpcInterceptor(interceptorParams),
      createSlugRpcInterceptor(),
    ],
  })

  const settingsStore = useSettingsStore()
  const endpointParams: CreateEndpointParams = {
    ...interceptorParams,
    transport,
    transportAnonymous,
    logout,
  }
  function variableEndpoints<T extends object> (createEndpoints: (params: CreateEndpointParams) => ApiVariableEndpoints<T>): ComputedRef<T> {
    const endpoints = createEndpoints(endpointParams)
    return computed(() => {
      if (settingsStore.protobuf) {
        return endpoints.grpc
      } else {
        return endpoints.django
      }
    })
  }
  const application = variableEndpoints(createApplicationEndpoints)
  const attachment = variableEndpoints(createAttachmentEndpoints)
  const comment = variableEndpoints(createCommentEndpoints)
  const event = variableEndpoints(createEventEndpoints)
  const policy = variableEndpoints(createPolicyEndpoints)
  const schema = variableEndpoints(createSchemaEndpoints)
  const task = variableEndpoints(createTaskEndpoints)
  const user = variableEndpoints(createUserEndpoints)
  const version = variableEndpoints(createVersionEndpoints)

  const refreshToken = computed(() => refreshTokenWritable.value)
  const spaToken = computed(() => spaTokenWritable.value)
  const spaTokenExpiration = computed(() => spaTokenExpirationWritable.value)

  return {
    refreshToken,
    spaToken,
    spaTokenExpiration,
    transport,
    logout,

    application,
    attachment,
    comment,
    event,
    policy,
    schema,
    task,
    user,
    version,
  }
})

hotReloadStore(useApiStore)
