import {
  CommodityType,
  CoverageType,
  HVCRequestType,
  LimitType,
  VehicleRemovalCodes,
} from '@policyfly/protobuf'

import { alertField, dateField, staticField } from '../components/fields'

import type { Condition } from '@policyfly/schema/types/shared/displayIf'
import type { DropdownItemObject } from '@policyfly/schema/types/shared/dropdown'
import type { FormComponent } from '@policyfly/schema/types/shared/formComponent'
import type { FormComponentAlert } from '@policyfly/schema/types/shared/formComponent/alert'
import type { FormComponentCollection } from '@policyfly/schema/types/shared/formComponent/collection'
import type { Button } from '@policyfly/schema/types/shared/formComponent/radio'
import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { FormContextField } from 'types/schema/shared/formSchema'

export type ScheduleCoverages = 'APD' | 'MTC'

export const COV_APD_PATH = `coverages.(type=${CoverageType.APD_COVERAGE})` as const
export const APD_INDICATIONS = `${COV_APD_PATH}.transportation.indications` as const

export const COV_MTC_PATH = `coverages.(type=${CoverageType.MTC_COVERAGE})` as const
export const MTC_INDICATIONS = `${COV_MTC_PATH}.transportation.indications` as const

export const COV_TOWING_STORAGE_PATH = `${COV_APD_PATH}.coverages.(type=${CoverageType.TOWING_STORAGE_REMOVAL_COVERAGE})` as const
export const TOWING_STORAGE_LIMIT_PATH = `${COV_TOWING_STORAGE_PATH}.moneys.limits.(type=${LimitType.TOWING_STORAGE_DEBRIS_REMOVAL_LIMIT})` as const

export const COV_HVC_PATH = `${COV_APD_PATH}.coverages.(type=${CoverageType.HVC_COVERAGE})` as const

export const MTC_LIMIT_ANY_ONE_UNIT_PATH = `${COV_MTC_PATH}.moneys.limits.(type=${LimitType.ANY_ONE_UNIT_LIMIT})` as const
export const MTC_LIMIT_ANY_ONE_LOSS_PATH = `${COV_MTC_PATH}.moneys.limits.(type=${LimitType.ANY_ONE_LOSS_LIMIT})` as const
export const MTC_LIMIT_ANY_ONE_COMBINATION_PATH = `${COV_MTC_PATH}.moneys.limits.(type=${LimitType.ANY_ONE_COMBINATION_LIMIT})` as const
export const MTC_LIMIT_TOTAL_PATH = `${COV_MTC_PATH}.moneys.limits.(type=${LimitType.TOTAL_LIMIT})` as const

export const COV_TI_PATH = `coverages.(type=${CoverageType.TI_COVERAGE})` as const
export const TI_LIMIT_PATH = `${COV_TI_PATH}.moneys.limits.(type=${LimitType.ANY_ONE_LOSS_LIMIT})` as const
export const COV_TI_WU_PATH = `${COV_TI_PATH}.coverages.(type=${CoverageType.TRAILER_INTERCHANGE_WHILST_UNATTACHED_COVERAGE})` as const

export const COV_TRIA_PATH = `coverages.(type=${CoverageType.TRIA_COVERAGE})` as const

export const COV_DEBRIS_REMOVAL_PATH = `coverages.(type=${CoverageType.DEBRIS_REMOVAL_COVERAGE})` as const
export const COV_EARNED_FREIGHT_PATH = `coverages.(type=${CoverageType.EARNED_FREIGHT_COVERAGE})` as const

export const QUOTE_PATH = '(selectedQuote)' as const

export type AdditionalCoveragesItem = Readonly<{
  label: string
  path: string
  displayIfCondition?: Condition<'pb'>[]
}>

export function policyDateFields<C extends FormCoverage> (): FormComponent<'pb', C>[] {
  return [
    dateField({
      label: 'Effective Date',
      data: {
        path: 'effective',
        source: 'pb',
      },
      rules: [
        'required',
        'validEffectiveDate',
      ],
      disabledFormTypes: ['QUOTE', 'APP_PENDING_INFO', 'APP_RENEWAL'],
    }),
    {
      type: 'computed',
      data: {
        path: 'expiration',
        source: 'pb',
      },
      calculation: {
        name: 'offsetDate',
        params: {
          valueData: { path: 'effective', source: 'pb' },
          yearOffset: 1,
        },
      },
      formTypes: ['APP_INITIAL', 'APP_RENEWAL'],
    },
  ]
}

export function policyExpirationDateField<C extends FormCoverage> (): FormComponent<'pb', C>[] {
  return [
    dateField({
      label: 'Expiration Date',
      data: {
        path: 'expiration',
        source: 'pb',
      },
      rules: [
        'required',
        'validExpirationDate',
        {
          name: 'compare',
          params: {
            validIf: { source: 'pb', path: 'effective', comparator: { name: 'lessThan' } },
            injectValue: 'comparator',
            errorMessage: 'Expiration Date must be later than Effective Date.',
          },
        },
      ],
      disabledFormTypes: ['QUOTE'],
    }),
  ]
}

export const hvcReasonsItems: Button<'pb'>[] = [
  { label: 'In Replacement of Scheduled Unit', value: HVCRequestType.REPLACE_HVC },
  { label: 'In Addition to Scheduled Unit', value: HVCRequestType.IN_ADDITION_HVC },
]

export const hvcReasonsMap = hvcReasonsItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.label }), {})

export const removalReasonsItems: Button<'pb'>[] = [
  { label: 'Change Amount Insured', value: VehicleRemovalCodes.CHANGE_AMOUNT_INSURED_VEHICLE_REMOVAL },
  { label: 'Added in error', value: VehicleRemovalCodes.ERRONEOUS_ADDITION_VEHICLE_REMOVAL },
  { label: 'Lease terminated', value: VehicleRemovalCodes.LEASE_TERMINATION_VEHICLE_REMOVAL },
  { label: 'Sold unit', value: VehicleRemovalCodes.SOLD_UNIT_VEHICLE_REMOVAL },
  { label: 'Total loss', value: VehicleRemovalCodes.TOTAL_LOSS_VEHICLE_REMOVAL },
  { label: 'Not advised', value: VehicleRemovalCodes.NOT_ADVISED_VEHICLE_REMOVAL },
  { label: 'Down for repair', value: VehicleRemovalCodes.MECHANICAL_FAULT_VEHICLE_REMOVAL },
  { label: 'Out of service', value: VehicleRemovalCodes.OUT_OF_SERVICE_VEHICLE_REMOVAL },
  { label: 'Coverage no longer needed', value: VehicleRemovalCodes.COVERAGE_NOT_NEEDED_VEHICLE_REMOVAL },
  { label: 'Other', value: VehicleRemovalCodes.OTHER_VEHICLE_REMOVAL },
]
export const removalReasonsMap = removalReasonsItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.label }), {})

export function apdOrMtcRequiredAlert ({ prefix = '', calculateCondition }: { prefix?: string, calculateCondition?: Condition<'pb'> }): FormComponentAlert<'pb'>[] {
  return [
    alertField({
      alert: {
        handler: 'displayIf',
        params: {
          condition: {
            path: [`${prefix}${COV_APD_PATH}.enabled`, `${prefix}${COV_MTC_PATH}.enabled`],
            source: 'pb',
            permittedValues: [true],
          },
          message: 'Either APD or MTC Coverage is required to submit.',
        },
      },
      immediate: true,
      calculateIf: calculateCondition ? [calculateCondition] : undefined,
    }),
  ]
}

export function basicInformationSummary (): FormComponent<'pb'>[] {
  return [
    staticField({
      label: 'Mailing Address',
      data: {
        path: 'primaryNamedInsured.address',
        source: 'pb',
      },
      formatter: 'address',
    }),
    staticField({
      label: 'Terminal/Garaging Address',
      data: {
        path: 'transportation.terminals.0.address',
        source: 'pb',
      },
      formatter: 'address',
      displayIf: { path: 'indicators.hasOtherMailingAddress', source: 'pb', permittedValues: [true] },
    }),
    staticField({
      label: 'Managing General Agent',
      data: {
        path: 'agency.name',
        source: 'pb',
      },
    }),
  ]
}

export const displayIfNotRemovedFromSchedule: Condition<'pb'> = { path: 'removedWith.id', source: 'pb', permittedValues: [null, 0], permitUndefined: true }
export const displayIfNewScheduleItem: Condition<'store' | 'pb'> = {
  path: 'applicationId',
  source: 'store',
  store: 'appContext',
  comparator: {
    name: 'equal',
    value: { path: 'addedWith.id', source: 'pb' },
  },
}
export const displayIfNotNewScheduleItem: Condition<'store' | 'pb'> = {
  path: 'applicationId',
  source: 'store',
  store: 'appContext',
  comparator: {
    name: 'notEqual',
    value: { path: 'addedWith.id', source: 'pb' },
  },
}

export const otherCommodityTypeItems: DropdownItemObject[] = [
  {
    text: 'Other',
    hint: 'Enter custom commodity description',
    value: CommodityType.COMMODITY_OTHER,
    includeIf: [
      {
        operator: 'OR',
        conditions: [
          {
            operator: 'AND',
            conditions: [
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_3, options: { field: 'type' } },
              },
            ],
          },
          {
            operator: 'AND',
            conditions: [
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER_3, options: { field: 'type' } },
              },
            ],
          },
          {
            operator: 'AND',
            conditions: [
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER_3, options: { field: 'type' } },
              },
            ],
          },
          {
            operator: 'AND',
            conditions: [
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
              },
              {
                path: '(root).commodities',
                source: 'pb',
                comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_3, options: { field: 'type' } },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: 'Other',
    hint: 'Enter custom commodity description',
    value: CommodityType.COMMODITY_OTHER_2,
    includeIf: [
      {
        path: '(root).commodities',
        source: 'pb',
        comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
      },
      {
        path: '(root).commodities',
        source: 'pb',
        comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
      },
    ],
  },
  {
    text: 'Other',
    hint: 'Enter custom commodity description',
    value: CommodityType.COMMODITY_OTHER_3,
    includeIf: [
      {
        path: '(root).commodities',
        source: 'pb',
        comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER, options: { field: 'type' } },
      },
      {
        path: '(root).commodities',
        source: 'pb',
        comparator: { name: 'includes', value: CommodityType.COMMODITY_OTHER_2, options: { field: 'type' } },
      },
      {
        path: '(root).commodities',
        source: 'pb',
        comparator: { name: 'notIncludes', value: CommodityType.COMMODITY_OTHER_3, options: { field: 'type' } },
      },
    ],
  },
]
export const otherCommoditiesMap = {
  [CommodityType.COMMODITY_OTHER]: '*Custom Commodity',
  [CommodityType.COMMODITY_OTHER_2]: '*Custom Commodity',
  [CommodityType.COMMODITY_OTHER_3]: '*Custom Commodity',
}

export function commoditiesCollection ({ additionalDetail }: { additionalDetail?: FormComponentCollection<'pb'>['list']['detail'][number] }): FormComponent<'pb'>[] {
  const details: FormComponentCollection<'pb'>['list']['detail'] = [
    { path: 'rates.maxValue', label: 'Max Load', formatter: 'currency' },
    { path: 'rates.avgValue', label: 'Avg Load', formatter: 'currency' },
  ]
  if (additionalDetail) {
    details.push(additionalDetail)
  }

  return [
    {
      data: {
        path: 'commodities',
        source: 'pb',
      },
      type: 'collection',
      itemName: 'Commodity',
      disabled: true,
      list: {
        headers: [
          { path: 'displayNameOverride', label: 'Type', colSize: 5 },
          { path: 'percentage', label: 'Percentage', formatter: 'percentage', colSize: 3 },
          {
            path: 'type',
            label: '',
            formatter: {
              name: 'mapValue',
              params: {
                defaultValue: '',
                valueMap: otherCommoditiesMap,
              },
            },
          },
        ],
        detail: details,
      },
      dialog: {
        height: 450,
      },
      arrayFields: [],
    },
  ]
}

export function coverageDisabledContextField ({ coveragePath }: { coveragePath: string }): FormContextField<'pb' | 'store'> {
  return {
    label: 'Coverage',
    data: {
      path: `${coveragePath}.enabled`,
      source: 'pb',
    },
    formatter: {
      name: 'mapValue',
      params: {
        valueMap: { false: 'OFF', true: 'ON' },
      },
    },
    displayIf: {
      operator: 'AND',
      conditions: [
        { path: `${coveragePath}.enabled`, source: 'pb', permittedValues: [false] },
        { path: 'isEndorsement', source: 'store', store: 'appContext', permittedValues: [false] },
      ],
    },
  }
}
