import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

import type { AgencyMembership, Membership, User } from 'types/user'

export interface State {
  id: User['id']
  avatar_url: User['avatar_url']
  first_name: User['first_name']
  last_name: User['last_name']
  email: User['email']
  isClerk: User['isClerk']
  sms_device_number: string | null | undefined
  provisionedNumber: string
  authenticator_url: string | null | undefined
  authUpdated: boolean
  agency_memberships: User['agency_memberships']
}

export const useUserStore = defineStore({
  id: 'user',

  state: (): State => ({
    id: 0,
    avatar_url: '',
    first_name: '',
    last_name: '',
    email: '',
    isClerk: false,
    sms_device_number: '',
    provisionedNumber: '',
    authenticator_url: '',
    authUpdated: false,
    agency_memberships: [],
  }),

  getters: {
    loaded: (state) => !!state.first_name,
    details: ({ id, avatar_url, first_name, last_name, email }) => ({ id, avatar_url, first_name, last_name, email }),
    hasAuthenticator: (state) => !!state.authenticator_url,
    hasSMS: (state) => !!state.sms_device_number,
    fullName: (state) => `${state.first_name} ${state.last_name}`,
    isAdministrator: (state) => state.agency_memberships.some((m) => m.is_administrator),
  },

  actions: {
    async load (): Promise<void> {
      const authenticationStore = useAuthenticationStore()
      const { data } = await api.users.account({})
      this.id = data.User.id
      this.avatar_url = data.User.avatar_url
      this.first_name = data.User.first_name
      this.last_name = data.User.last_name
      this.email = data.User.email
      this.isClerk = data.User.isClerk
      this.agency_memberships = data.User.agency_memberships
      const { agency_memberships: userAgencyMemberships, membership, id } = data.User

      // switch where to load this information from based on user structure
      let programList: Membership[] = []
      let agencyMemberships: AgencyMembership[] = []
      // if no memberships then this is an agent/broker
      const isAgent = !membership?.length
      if (isAgent) {
        const [agencyMembership] = userAgencyMemberships
        if (agencyMembership) {
          programList = agencyMembership.agency.programs.map((program) => ({
            subscription: 'BROKER',
            default_program: false,
            program,
          }))
          agencyMemberships = userAgencyMemberships
        }
      } else {
        programList = membership
      }

      authenticationStore.userId = id
      authenticationStore.programs = programList
      authenticationStore.agencyMemberships = agencyMemberships

      const deviceRes = await api.users.devices({ path: { id: this.id } })
      this.sms_device_number = deviceRes.data.sms_device_number
      this.authenticator_url = deviceRes.data.authenticator_url
    },
    async editDetails (data: FormData): Promise<void> {
      const { id } = this
      const res = await api.users.edit({ path: { id }, body: data })
      this.avatar_url = res.data.User.avatar_url
      this.first_name = res.data.User.first_name
      this.last_name = res.data.User.last_name
      this.email = res.data.User.email
    },
    provisionSMS (number?: string): ReturnType<typeof api.users.provisionSMS> {
      if (number) this.provisionedNumber = number
      const { email, provisionedNumber } = this
      return api.users.provisionSMS({ body: { email, phone_number: provisionedNumber } })
    },
    async validateSMS (token: string): Promise<void> {
      const { email } = this
      const res = await api.users.validateSMSUpdate({ body: { email, challenge_token: Number(token) } })
      this.sms_device_number = String(res.data.valid_number)
      this.provisionedNumber = ''
      this.authUpdated = true
    },
    async clear2fa (device: 'totp' | 'sms'): Promise<Awaited<ReturnType<typeof api.users.clear>>> {
      if (!['totp', 'sms'].includes(device)) {
        throw new Error(`Device type ${device} not allowed.`)
      }
      const res = await api.users.clear({ path: { device_type: device } })
      if (device === 'totp') this.authenticator_url = ''
      if (device === 'sms') this.sms_device_number = ''
      return res
    },
    provisionQR (): ReturnType<typeof api.users.provisionQR> {
      const { email } = this
      return api.users.provisionQR({ body: { email } })
    },
    async validateQR (token: string): Promise<void> {
      const apiStore = useApiStore()
      const { email } = this
      await apiStore.user.register2FA({ email, token, mode: 'totp' })
      // TODO: if the authenticator_url does end up being used somewhere we'll have to hit the devices endpoint to retrieve it again, as for now we just need some value
      this.authenticator_url = 'updated'
      this.authUpdated = true
    },
    /**
     * Attempts to log the user out of the application and invalidate the session with the API.
     * Removes all tokens and authentication data from stores.
     */
    async logout (): Promise<void> {
      try {
        const apiStore = useApiStore()
        await apiStore.user.logout()
      } catch (err) {
        console.error(`Error invalidating session with API. Clearing local data. ${err}`)
      } finally {
        this.$reset()
        const authenticationStore = useAuthenticationStore()
        authenticationStore.$reset()
      }
    },
  },
})

hotReloadStore(useUserStore)
